import { Entity } from '@backstage/catalog-model';

const validateComponent = (entity: Entity): boolean =>
  entity?.metadata.annotations?.['argocd/has-apps'] === 'true';

const validateSystem = (entity: Entity): boolean =>
  Boolean(
    entity?.relations?.some(
      (relation) => relation.type === 'hasPart' && relation.targetRef.startsWith('component:')
    )
  );

const rulesMappedToKind = new Map<string, (entity: Entity) => boolean>([
  ['Component', validateComponent],
  ['System', validateSystem]
]);

export const isCostEnable = (entity: Entity) =>
  rulesMappedToKind.get(entity.kind)?.(entity) ?? false;
