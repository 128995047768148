export const internalEvents: { [key: string]: Record<string, string> } = {
  click: {
    subject: 'link_text',
    event_name: 'click'
  },
  search: {
    subject: 'search_term',
    event_name: 'search'
  }
};
