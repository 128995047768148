import { Config } from '@backstage/config';
import {
  AnalyticsApi,
  analyticsApiRef,
  AnalyticsEvent,
  configApiRef
} from '@backstage/core-plugin-api';
import { createApiFactory } from '@backstage/frontend-plugin-api';
import { GoogleAnalyticsApiParse } from './googleAnalyticsParse';
import { GTAG } from './gtag';

export class GoogleAnalyticsApi implements AnalyticsApi {
  private readonly blocklist = ['navigate'];
  private readonly googleAnalyticsParse: GoogleAnalyticsApiParse;
  private readonly _gtag: GTAG;

  private constructor(
    readonly measurementId: string,
    readonly debugMode: boolean,
    readonly testMode: boolean,
    readonly sendPageViews: boolean
  ) {
    this.googleAnalyticsParse = new GoogleAnalyticsApiParse();
    this._gtag = new GTAG(testMode, debugMode, sendPageViews);

    this._gtag.initialize(measurementId);
  }

  static fromConfig(config: Config) {
    const measurementId = config.getString('app.analytics.ga4.measurementId');
    const debugMode = config.getOptionalBoolean('app.analytics.ga4.debug') ?? false;
    const testMode = config.getOptionalBoolean('app.analytics.ga4.testMode') ?? false;
    const sendPageViews = config.getOptionalBoolean('app.analytics.ga4.sendPageViews') ?? true;

    return new GoogleAnalyticsApi(measurementId, debugMode, testMode, sendPageViews);
  }

  captureEvent(event: AnalyticsEvent): void {
    if (this.canSendEvent(event)) {
      const eventsParserd = this.googleAnalyticsParse.parseEvent(event);
      this._gtag.event(eventsParserd);
    }
  }

  private canSendEvent({ action }: AnalyticsEvent): boolean {
    return !this.blocklist.includes(action);
  }
}
export const googleAnalyticsApi = createApiFactory({
  api: analyticsApiRef,
  deps: { configApi: configApiRef },
  factory: ({ configApi }) => GoogleAnalyticsApi.fromConfig(configApi)
});
