import React from 'react';
import { ItemCardHeader } from '@backstage/core-components';
import type { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { useHeaderStyle } from './styles';
import useTheme from '@mui/styles/useTheme';
import { FavoriteEntity } from '@backstage/plugin-catalog-react';

const TemplateCardHeaderSubTitle = ({
  subTitle,
  classes,
  ...props
}: TemplateEntityV1beta3 & {
  subTitle: string;
  classes: string;
}) => {
  return (
    <div className={classes}>
      {subTitle}
      <FavoriteEntity entity={props} />
    </div>
  );
};
export const TemplateCardHeader = (props: TemplateEntityV1beta3) => {
  const {
    metadata: { title, name },
    spec: { type }
  } = props;
  const { getPageTheme } = useTheme();
  const themeByType = getPageTheme({ themeId: type });
  const styles = useHeaderStyle({
    cardBackgroundImage: themeByType.backgroundImage,
    cardFontColor: themeByType.fontColor
  });

  return (
    <ItemCardHeader
      title={title ?? name}
      subtitle={<TemplateCardHeaderSubTitle {...props} subTitle={type} classes={styles.subTitle} />}
      classes={{
        root: styles.header
      }}
    />
  );
};
