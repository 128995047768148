import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { taskCreatePermission } from '@backstage/plugin-scaffolder-common/alpha';
import type { TemplateCardLinksProps } from './type';
import { useAnalytics } from '@backstage/core-plugin-api';

export const useTemplateCard = (
  entity: TemplateEntityV1beta3,
  additionalLinks?: TemplateCardLinksProps[]
) => {
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const hasTags = !!entity.metadata.tags?.length;
  const hasLinks = !!additionalLinks?.length;
  const displayDefaultDivider = !hasTags && !hasLinks;
  const allLinks = [...(additionalLinks ?? [])];
  const { allowed: canCreateTask } = usePermission({
    permission: taskCreatePermission
  });
  const analytics = useAnalytics();
  const handleChooseTemplate = ({ metadata: { name } }: TemplateEntityV1beta3) => {
    analytics.captureEvent('interaction_choose_template', 'choose a template', {
      attributes: {
        cd_interaction_detail: `click:choose:${name}`
      }
    });
  };

  return {
    hasTags,
    canCreateTask,
    ownedByRelations,
    displayDefaultDivider,
    hasLinks,
    allLinks,
    handleChooseTemplate
  };
};
