import React from 'react';
import {
  type DefaultEntityFilters,
  DefaultFilters,
  useEntityList
} from '@backstage/plugin-catalog-react';
import { useAnalytics } from '@backstage/core-plugin-api';

const filterCached: { filters: DefaultEntityFilters } = { filters: {} };

const staticKeys = {
  kind: 'value',
  type: 'value',
  owners: 'values',
  lifecycles: 'values',
  namespace: 'values',
  tags: 'values',
  orphan: 'value',
  error: 'value',
  user: 'value'
};
const extractValuesFromObjects = (obj: string | string[] | undefined):any => {
  if (Array.isArray(obj)) return obj.join('|');
  return obj;
};

const hasChangedFilter = (previousFilter: string | undefined, currentFilter: string | undefined) =>
  previousFilter !== currentFilter;

export const CatalogFilter = () => {
  const { filters } = useEntityList();
  const analytics = useAnalytics();

  if (!Object.keys(filterCached.filters ?? {}).length) {
    filterCached.filters = filters;
  }

  const sendFilterMetricsAnalytics = (filters: any, staticKeys: any, filterCached: any) => {
    Object.entries(filters).forEach(([key, value]) => {
      const keyType = staticKeys[key];
      if (value && filters[key]) {
        const cachedFormatedValue = extractValuesFromObjects(
          filterCached.filters[key]?.[keyType]
        );
        const currentFormatedValue = extractValuesFromObjects(filters[key][keyType]);
        if (hasChangedFilter(cachedFormatedValue, currentFormatedValue)) {
          analytics.captureEvent('interaction_filter_components', 'choose filter', {
            attributes: {
              cd_interaction_detail: `filter:${key}:${currentFormatedValue}`
            }
          });
          filterCached.filters = filters;
        }
      }
    });
  };

  sendFilterMetricsAnalytics(filters, staticKeys, filterCached);

  return <DefaultFilters />;
};