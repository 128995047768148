import { CompoundEntityRef, Entity, parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { catalogApiRef, humanizeEntityRef } from '@backstage/plugin-catalog-react';

type GBTechEntityPickerProps = {
  kind: string;
  exclusionEntities?: string[];
  filters?: any;
};

const filterByKind = (entity: Entity, kind: string) => entity.kind.toLowerCase() === kind;

const filterByRelationKind = (relations: any[], relationKind: string) =>
  relations?.filter((relation: any) => parseEntityRef(relation.targetRef).kind === relationKind);

const getSystemRelations = (entities: Entity[]) => {
  return entities
    ?.map((entity: Entity) => {
      const relations = filterByRelationKind(entity.relations ?? [], 'system');
      return { ...entity, relations };
    })
    ?.map((entity: Entity) =>
      entity.relations?.map((relation: any) => ({
        metadata: { name: parseEntityRef(relation.targetRef).name },
        spec: { domain: entity.metadata.name }
      }))
    )
    ?.flat();
};

export const useGBTechEntityPicker = ({
  exclusionEntities,
  kind,
  filters
}: GBTechEntityPickerProps) => {
  const [entities, setEntities] = useState<any[]>([]);

  const catalogApi = useApi(catalogApiRef);

  const populateSystemPicker = (entities: Entity[]) => {
    let relationKind = 'domain';
    let filteredEntities = entities?.filter((entity: Entity) => filterByKind(entity, 'system'));

    if (!filteredEntities.length) {
      filteredEntities = entities?.filter((entity: Entity) => filterByKind(entity, 'domain'));
      relationKind = 'system';
    }

    let systemsWithDomain = filteredEntities.filter(
      (entity: Entity) => !!filterByRelationKind(entity.relations ?? [], relationKind).length
    );

    if (relationKind === 'system') {
      systemsWithDomain = getSystemRelations(systemsWithDomain) as unknown as Entity[];
    }
    const systems = systemsWithDomain
      .map((entity: Entity) => ({
        system: entity.metadata.name,
        domain: entity.spec?.domain ?? ''
      }))
      .sort((a, b) => (a.domain > b.domain ? 1 : a.domain < b.domain ? -1 : 0));

    setEntities(systems);
  };

  const catalogState = useAsync(async () => {
    const entitiesData = await catalogApi.getEntities(filters);
    const filteredEntities = entitiesData.items?.filter(
      (entity) => !exclusionEntities?.includes(entity.metadata.name)
    );

    if (kind.toLowerCase() === 'domain') {
      populateSystemPicker(filteredEntities);
    } else {
      setEntities(
        filteredEntities.map((e: Entity | CompoundEntityRef) =>
          humanizeEntityRef(e, { defaultKind: kind })
        )
      );
    }
    return entitiesData;
  }, [kind]);

  return {
    loading: catalogState.loading,
    entities
  };
};
