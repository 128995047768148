import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type HeaderCardProps = {
  cardBackgroundImage: string;
  cardFontColor: string;
};
export const useHeaderStyle = makeStyles<Theme, HeaderCardProps>(() => ({
  header: {
    backgroundImage: ({ cardBackgroundImage }) => cardBackgroundImage,
    cardFontColor: ({ cardFontColor }) => cardFontColor
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(2)
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical'
  },
  markdown: {
    '& :first-child': {
      margin: 0
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  ownedBy: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    color: theme.palette.link
  },
  actionContainer: {
    padding: theme.spacing(0)
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));
