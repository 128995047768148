import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export interface TemplateCardTagsProps {
  tags: string[] | undefined;
}
export const TemplateCardTags = ({ tags }: TemplateCardTagsProps) => (
  <>
    <Grid item xs={12}>
      <Divider data-testid="template-card-separator--tags" style={{ margin: '8px 0' }} />
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2} data-testid="template-card-tags">
        {tags?.map((tag) => (
          <Grid key={`grid-${tag}`} item data-testid={`template-card-tag-item-${tag}`}>
            <Chip
              style={{ margin: 0 }}
              size="small"
              data-testid={`template-card-tag-chip-${tag}`}
              label={tag}
              key={tag}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </>
);
