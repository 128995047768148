import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TemplateCardHeader } from './templateCardHeader';
import Grid from '@mui/material/Grid';
import React from 'react';
import { TemplateCardContent } from './templateCardContent';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { useTemplateCard } from './useTemplateCard';
import Divider from '@mui/material/Divider';
import { TemplateCardTags } from './templateCardTags';
import { IconComponent } from '@backstage/core-plugin-api';
import { TemplateCardLinks } from './templateCardLinks';
import { useStyles } from './styles';
import CardActions from '@mui/material/CardActions';
import { UserIcon } from '@backstage/core-components';
import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import { Box, Button } from '@mui/material';

type TemplateCardProps = {
  template: TemplateEntityV1beta3;
  additionalLinks?: {
    icon: IconComponent;
    text: string;
    url: string;
  }[];
  onSelected?: (template: TemplateEntityV1beta3) => void;
};

export const TemplateCard = ({ template, additionalLinks, onSelected }: TemplateCardProps) => {
  const {
    hasTags,
    displayDefaultDivider,
    hasLinks,
    allLinks,
    ownedByRelations,
    canCreateTask,
    handleChooseTemplate
  } = useTemplateCard(template, additionalLinks);
  const styles = useStyles();
  return (
    <Card>
      <TemplateCardHeader {...template} />
      <CardContent>
        <Grid
          spacing={2}
          container
          data-testid="template-card-content"
          className={styles.cardContent}
        >
          <TemplateCardContent description={template.metadata.description ?? 'Sem descrição'} />
          {displayDefaultDivider && (
            <Grid item xs={12}>
              <Divider style={{ margin: '8px 0' }} />
            </Grid>
          )}
          {hasTags && <TemplateCardTags tags={template.metadata.tags} />}
          {hasLinks && <TemplateCardLinks links={allLinks} />}
        </Grid>
      </CardContent>
      <CardActions className={styles.actionContainer}>
        <Box className={styles.footer} data-testid="template-card-actions--footer">
          <Box className={styles.ownedBy} data-testid="template-card-actions--ownedby">
            {ownedByRelations.length > 0 && (
              <>
                <UserIcon fontSize="small" />
                <EntityRefLinks
                  style={{ marginLeft: '8px' }}
                  entityRefs={ownedByRelations}
                  defaultKind="Group"
                  hideIcons
                />
              </>
            )}
          </Box>
          {canCreateTask ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              data-testid="template-card-actions--create"
              onClick={() => {
                handleChooseTemplate(template);
                onSelected?.(template);
              }}
            >
              Choose
            </Button>
          ) : null}
        </Box>
      </CardActions>
    </Card>
  );
};
