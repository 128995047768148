import { AnyApiFactory } from '@backstage/core-plugin-api';
import { techRadarApi } from './techRadar';
import { scmAuthApi, scmAuthDefaultApi } from './scmAuth';
import { catalogImportApi } from './catalogImport';
import { googleAnalyticsApi } from './analytics';
export const apis: AnyApiFactory[] = [
  scmAuthApi,
  scmAuthDefaultApi,
  techRadarApi,
  catalogImportApi,
  googleAnalyticsApi
];
