import { MarkdownContent } from '@backstage/core-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useStyles } from './styles';

export interface CardContentProps {
  description: string;
}
export const TemplateCardContent = ({ description }: CardContentProps) => {
  const styles = useStyles();
  return (
    <Grid item xs={12} data-testid="template-card-content-grid">
      <Box className={styles.box} data-testid="template-card-content-container">
        <MarkdownContent className={styles.markdown} content={description} />
      </Box>
    </Grid>
  );
};
