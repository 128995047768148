import { homePlugin } from '@backstage/plugin-home';
import { createCardExtension } from '@backstage/plugin-home-react';
import type { HomePageToolkitProps } from './types';

export const HomeToolkit = homePlugin.provide(
  createCardExtension<HomePageToolkitProps>({
    title: 'Toolkit',
    name: 'HomeToolkit',
    components: () => import('./Content')
  })
);
