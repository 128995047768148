import {
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { scmAuthApiRef, scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  CatalogImportApi,
  catalogImportApiRef,
  CatalogImportClient
} from '@backstage/plugin-catalog-import';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const catalogImportApi = createApiFactory({
  api: catalogImportApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    scmAuthApi: scmAuthApiRef,
    fetchApi: fetchApiRef,
    scmIntegrationsApi: scmIntegrationsApiRef,
    catalogApi: catalogApiRef,
    configApi: configApiRef
  },
  factory: ({ discoveryApi, scmAuthApi, fetchApi, scmIntegrationsApi, catalogApi, configApi }) => {
    const catalogImportClient = new CatalogImportClient({
      discoveryApi,
      scmAuthApi,
      fetchApi,
      scmIntegrationsApi,
      catalogApi,
      configApi
    }) as CatalogImportApi;
    catalogImportClient.preparePullRequest = undefined;
    return catalogImportClient;
  }
});
