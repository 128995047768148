import { AnalyticsEvent } from '@backstage/core-plugin-api';
import { GoogleAnalyticsEvents } from './types';
import { internalEvents } from './internalEvents';

export class GoogleAnalyticsApiParse {
  parseEvent(event: AnalyticsEvent): GoogleAnalyticsEvents {
    if (this.isInternalBackstageEvent(event)) {
      return this.parseDefaultEvent(event);
    }
    return this.parseCustomEvent(event);
  }

  private parseDefaultEvent(
    event: Omit<AnalyticsEvent, 'attributes' | 'context'>
  ): GoogleAnalyticsEvents {
    const { action, ...attributes } = event;
    const parsedProperties = Object.keys(internalEvents?.[action] ?? {}).reduce(
      (acc: Record<string, string>, key) => {
        const parseKey = internalEvents[action][key];
        if (key in attributes) {
          acc[parseKey] = (attributes as unknown as Record<string, string>)[key];
        } else {
          acc[key] = parseKey;
        }
        return acc;
      },
      {}
    );
    return parsedProperties;
  }

  private parseCustomEvent(event: Omit<AnalyticsEvent, 'value'>): GoogleAnalyticsEvents {
    return {
      event_name: event.action,
      ...event.attributes
    };
  }

  private isInternalBackstageEvent({ action }: AnalyticsEvent): boolean {
    const actions = Object.keys(internalEvents);
    return actions.includes(action);
  }
}
