import Box from '@mui/material/Box';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import { Link } from '@backstage/core-components';
import React from 'react';
import type { TemplateCardLinksProps } from './type';
import { useStyles } from './styles';

export const TemplateCardLinks = ({ links }: { links: TemplateCardLinksProps[] }) => {
  const styles = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <Divider data-testid="template-card-separator--links" style={{ margin: '8px 0' }} />
      </Grid>
      <Grid item xs={12}>
        <Grid spacing={2} container data-testid="template-card-links">
          {links?.map(({ icon: Icon, text, url, title }) => (
            <Grid
              className={styles.linkText}
              item
              xs={6}
              key={url}
              data-testid="template-card-links--item"
            >
              <Box className={styles.linkText}>
                <Icon fontSize="small" />
                <Link style={{ marginLeft: '8px' }} to={url}>
                  {text || title || url}
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
