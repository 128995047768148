import React from 'react';

import { HomePageCompanyLogo, HomePageStarredEntities } from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { configApiRef, useAnalytics, useApi } from '@backstage/core-plugin-api';
import GamesIcon from '@mui/icons-material/Games';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import useTheme from '@mui/styles/useTheme';

import Grid from '@mui/material/Grid';

import LogoHome from '../../components/LogoHome';

import { useStyles } from './styles';
import { HomeToolkit } from './components/Toolkit';

export const HomePage = () => {
  const S = useStyles();
  const config = useApi(configApiRef);
  const onboardingUrl: string = config.get('urls.onboarding');
  const playgroundUrl: string = config.get('urls.playground');
  const baseUrl: string = config.get('app.baseUrl') || 'https://alquimia.gb.tech';
  const isPlayground = baseUrl.includes('play');
  const theme = useTheme().palette.mode;
  const analytics = useAnalytics();
  const dynamicTools = [
    {
      url: onboardingUrl,
      label: 'Onboarding',
      icon: <OndemandVideoIcon />,
      value: 'onboarding'
    },
    ...(!isPlayground
      ? [
          {
            url: playgroundUrl,
            label: 'Playground',
            icon: <GamesIcon />,
            value: 'playground'
          }
        ]
      : [])
  ];
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const term = (e.target as HTMLInputElement).value;
      analytics.captureEvent('callback_search_success', '', {
        attributes: {
          cd_interaction_detail: `search:${term}`
        }
      });
    }
  };
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content className={S.hometheme}>
          <Grid container justifyContent="center" spacing={4}>
            <HomePageCompanyLogo className={S.container} logo={<LogoHome theme={theme} />} />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                onKeyDown={handleKeyDown}
                debounceTime={500}
                InputProps={{
                  classes: {
                    root: S.searchBar,
                    notchedOutline: S.searchBarOutline
                  }
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={10}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <HomeToolkit tools={dynamicTools} />
                </Grid>
                <Grid item xs={6} className={S.cont}>
                  <HomePageStarredEntities />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
