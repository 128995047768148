import { configApiRef, createApiFactory } from '@backstage/core-plugin-api';
import { ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth } from '@backstage/integration-react';

export const scmAuthApi = createApiFactory({
  api: scmIntegrationsApiRef,
  deps: { configApi: configApiRef },
  factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi)
});

export const scmAuthDefaultApi = ScmAuth.createDefaultApiFactory();
